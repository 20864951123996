import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Slider } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class SliderModel {
  protected endpoint = `${process.env.VUE_APP_SERVICE_MEDIA}/slider`
  protected privateEndpoint = `${process.env.VUE_APP_SERVICE_MEDIA_PRIVATE}/slider`

  public static async list() {
    const endpoint = new this().endpoint + '/show-slider'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}?&per_page=1000`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(payload: any) {
    const endpoint = new this().privateEndpoint

    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('route', payload.route)
    formData.append('image', payload.image)
    formData.append('ordering', payload.ordering)
    formData.append('is_active', payload.is_active)

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}/add`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: number): Promise<ResourceResponse<Slider>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/show/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(id: number, payload: any) {
    const endpoint = new this().privateEndpoint

    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('route', payload.route)
    formData.append('image', payload.image)
    formData.append('is_active', payload.is_active)

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number) {
    const endpoint = new this().privateEndpoint

    const {
      data: { success },
    } = await Http.delete(`${endpoint}/delete/${id}`)

    return {
      success,
    }
  }
}
