
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { Slider, Classroom, MyWebinar, Cart } from '@/dto'
import SliderModel from '@/models/SliderModel'
import ClassroomModel from '@/models/ClassroomModel'
import CartModel from '@/models/CartModel'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import Auth from '@/utils/Auth'
import moment from 'moment'
import Loading from '@/main/components/Loading.vue'
import RedeemVoucher from '@/main/components/Cards/RedeemVoucher.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home Page',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    RedeemVoucher,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      sliders: [] as Slider[],
      allClassrooms: [] as Classroom[],
      voucher: '',
      carts: [] as Cart[],
      showModalVoucher: false,
      redeemStatus: false,
      loadingVoucher: false,
      voucherDetail: '' as any,
      highlight: [] as any,
      loadingHighlight: false,
      myClasses: [] as any,
      loadedClassroomAmount: 0 as number,
      loadingMyClass: false,
      loadingCategory: false,
      categories: [] as any,
      isLoadingMyClassroom: false,
      myWebinars: [] as MyWebinar[],
      openWebinar: '',
      datesWebinar: [] as string[],
      showWebinar: [] as any,
      showModalAddToCart: false,
      showModalCategory: false,
      news: [] as any,
      showLoading: false as boolean,

      showRedeemVoucher: false as boolean,
      codeRedeemVoucher: '' as string,
      loadingRedeemVoucher: false as boolean,
      selectedClassroom: {} as Classroom,
    }
  },
  async created() {
    if (!Auth.isLoggedIn()) {
      window.location.href = 'https://www.sekolahdesain.id'
    }
    await this.getHighlight()
    if (window.innerWidth < 768) {
      const popular: HTMLElement = document.getElementById(
        'popular'
      ) as HTMLElement
      popular?.children[0]?.children[0]?.children[0]?.setAttribute(
        'style',
        'transform: translateX(0);'
      )
    } else {
      if (this.highlight?.classrooms?.length > 1) {
        const popular: HTMLElement = document.getElementById(
          'popular'
        ) as HTMLElement
        popular?.children[0]?.children[0]?.children[0]?.setAttribute(
          'style',
          'transform: translateX(-13rem);'
        )
      } else {
        const popular: HTMLElement = document.getElementById(
          'popular'
        ) as HTMLElement
        popular?.children[0]?.children[0]?.children[0]?.setAttribute(
          'style',
          'transform: translateX(19rem);'
        )
      }
    }
  },
  async mounted() {
    this.getSliders()
    this.$store.dispatch('homeStore/fetchCategories').then(() => {
      // for pagination
      this.categories = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      this.loadingCategory = this.getLoadingCategory
    })
    this.getNews()
    if (Auth.isLoggedIn()) {
      this.$store.dispatch('homeStore/fetchMyWebinar').then(() => {
        if (this.$store.getters['homeStore/getMyWebinar']?.length > 0) {
          this.myWebinars = this.$store.getters[
            'homeStore/getMyWebinar'
          ].filter((el: any) => {
            if (this.dateOnLive(el?.expired_at) >= this.dateNow()) {
              return el
            }
          })
        }
      })
      this.myClassrooms()
    }
  },

  computed: {
    getLoadingCategory(): boolean {
      return this.$store.getters['homeStore/getLoadingCategory']
    },
    getCategoriesData(): Array<any> {
      const category = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      return category
    },
    getLoadingMyWebinar(): boolean {
      return this.$store.getters['homeStore/getLoadingMyWebinar']
    },
    getMyWebinar(): Array<any> {
      return this.$store.getters['homeStore/getMyWebinar']
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
  },
  methods: {
    async getSliders() {
      const { data } = await SliderModel.list()
      this.sliders = data
        .sort(
          (a: { ordering: number }, b: { ordering: number }) =>
            a.ordering - b.ordering
        )
        .filter((e: Slider) => e.is_active)
    },
    closeModalCategory() {
      this.showModalCategory = false
    },
    closeModalVoucher() {
      this.showModalVoucher = false
      this.voucher = ''
      this.voucherDetail = null
    },
    async redeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.voucher) {
          this.loadingVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.voucher
            )
            if (success) {
              this.myClassrooms()
              this.voucherDetail = message
              this.loadingVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },

    async getHighlight() {
      if (Auth.isLoggedIn()) {
        await ClassroomModel.getHighlightPrivate()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
              this.loadingHighlight = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        await ClassroomModel.getHighlight()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
              this.loadingHighlight = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    async myClassrooms() {
      this.loadedClassroomAmount = 9999
      this.$store
        .dispatch('homeStore/fetchMyClass', {
          page: 1,
          showPerPage: 9999,
        })
        .then(() => {
          const {
            'homeStore/getLoadingSelfClassroom': getLoadingSelfClassroom,
            'homeStore/getSelfClassrooms': getSelfDataClassrooms,
          } = this.$store.getters
          this.myClasses = getSelfDataClassrooms
          this.loadingMyClass = getLoadingSelfClassroom
        })
    },

    async getNews() {
      await ClassroomModel.newsList(1, 25)
        .then(res => {
          if (res.success) {
            this.news = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    isPreOrder(date: string) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > this.timeZone(date)) {
        return false
      } else {
        return true
      }
    },

    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },

    redirectClassroom(code: string, slug: string) {
      if (slug != '') {
        window.location.href = slug
      } else {
        this.$router.push('/classroom/' + code + '/')
      }
    },

    async addToCart(code: string) {
      if (Auth.isLoggedIn()) {
        this.showLoading = true
        await CartModel.addSingle(code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.showModalAddToCart = true
            }
            this.showLoading = false
          })
          .catch(err => {
            this.showLoading = false
            console.log(err)
          })
      } else {
        this.$router.push('/register?redirect=/classroom/' + code)
      }
    },

    closeModalAddToCart() {
      this.showModalAddToCart = false
    },

    cartTotalPriceFormat() {
      let sum = 0
      this.getCartData.forEach((el: any) => {
        sum += el.price
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    getDateWebinar(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY')
    },

    getTimeWebinar(start: string, end: string) {
      const hourStart = moment(start).format('HH:mm')
      const hourEnd = moment(end).format('HH:mm')
      return hourStart + ' - ' + hourEnd + ' WIB'
    },

    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },

    newsFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY')
    },

    dateOnLive(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    beforeAddToCart(classroom: any) {
      if (classroom.is_purchased) {
        this.$router.push('/classroom/' + classroom?.code + '/activity')
      } else {
        if (classroom?.must_voucher) {
          this.selectedClassroom = classroom
          this.openRedeemVoucher()
        } else {
          this.addToCart(classroom.code)
        }
      }
    },
    checkWorkshopClass(code: string) {
      if (code.charAt(0) == 'W' || code.charAt(0) == 'M') {
        return true
      } else {
        return false
      }
    },

    closeRedeemVoucher() {
      this.showRedeemVoucher = false
    },

    openRedeemVoucher() {
      this.showRedeemVoucher = true
      this.codeRedeemVoucher = ''
      this.loadingRedeemVoucher = false
    },

    async submitRedeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.codeRedeemVoucher) {
          this.loadingRedeemVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.codeRedeemVoucher
            )
            if (success) {
              this.closeRedeemVoucher()
              this.myClassrooms()
              this.voucherDetail = message
              this.loadingRedeemVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingRedeemVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingRedeemVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },
  },
})
