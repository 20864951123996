<template>
  <div class="min-h-screen">
    <!-- Section Slider -->
    <div class="md:mt-3 max-w-6xl md:px-5 mx-auto slider">
      <vueper-slides
        v-if="sliders.length > 0"
        :infinite="true"
        autoplay
        :arrows="false"
        :dragging-distance="50"
        :duration="5000"
        class="no-shadow"
      >
        <vueper-slide
          v-for="(slide, i) in sliders"
          :key="i"
          :title="slide.name"
        >
          <template v-slot:content>
            <a :href="slide.route" target="_blank" class="md:rounded-md">
              <img
                :src="slide.image"
                class="w-full md:rounded-md"
                :alt="'slider ' + i"
              />
            </a>
          </template>
        </vueper-slide>
        <template #bullet="{ active }">
          <span
            class="h-2 rounded-full inline-block"
            :class="active ? ' w-20 bg-[#FFC200]' : ' w-2 bg-white'"
          ></span>
        </template>
      </vueper-slides>
    </div>

    <!-- Section Kategori -->
    <div class="max-w-6xl p-3 mx-auto">
      <div class="grid grid-cols-4 md:grid-cols-8 gap-0">
        <router-link
          v-for="(category, i) in categories.slice(0, 7)"
          :key="i"
          :to="'/category?q=' + category.slug"
          class="col-span-1 flex flex-col items-center justify-center my-3 text-[#212121] hover:text-[#0A72ED]"
        >
          <img
            :src="category?.thumbnail"
            alt=""
            class="rounded-full h-16 w-16"
          />
          <div class="font-solusi font-semibold text-xs mt-1 text-center">
            {{ category.name }}
          </div>
        </router-link>
        <div
          @click="showModalCategory = true"
          class="col-span-1 flex flex-col items-center justify-center my-3 text-[#212121] hover:text-[#0A72ED] cursor-pointer"
        >
          <svg
            class="w-16 h-16"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="40" cy="40" r="40" fill="#F5F7FD" />
            <path
              d="M23.4004 24.4001V39.0001H39.0004V23.4001H24.4004C23.8488 23.4001 23.4004 23.8486 23.4004 24.4001Z"
              fill="#757575"
            />
            <path
              d="M55.6 23.4001H41V39.0001H56.6V24.4001C56.6 24.1345 56.4953 23.8798 56.3078 23.6923C56.1203 23.5049 55.8656 23.4002 55.6 23.4002L55.6 23.4001Z"
              fill="#757575"
            />
            <path
              d="M23.4004 55.6003C23.4004 55.8659 23.5051 56.1206 23.6926 56.3081C23.8801 56.4955 24.1348 56.6002 24.4004 56.6002H39.0004V41.0002H23.4004L23.4004 55.6003Z"
              fill="#757575"
            />
            <path
              d="M41 56.6002H55.6C55.8656 56.6002 56.1203 56.4955 56.3078 56.308C56.4953 56.1205 56.6 55.8658 56.6 55.6002V41.0002H41L41 56.6002Z"
              fill="#757575"
            />
          </svg>
          <div class="font-solusi font-semibold text-xs mt-1 text-center">
            Semua Kategori
          </div>
        </div>
      </div>
    </div>

    <!-- Section Tukarkan Voucher -->
    <div class="max-w-6xl p-3 mx-auto">
      <div class="bg-[#FFFAF1] w-full px-3 flex justify-between rounded-md">
        <svg
          class="hidden md:block"
          width="109"
          height="136"
          viewBox="0 0 109 136"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M89 28.8C85.0251 28.8 81.8 32.0352 81.8 36C81.8 39.9744 85.0251 43.2 89 43.2C92.9658 43.2 96.2 39.9744 96.2 36C96.2 32.0352 92.9658 28.8 89 28.8ZM89 48C82.3813 48 77 42.6192 77 36C77 29.3808 82.3813 24 89 24C95.6187 24 101 29.3808 101 36C101 42.6192 95.6187 48 89 48Z"
            fill="#FFC200"
          />
          <path
            opacity="0.1"
            d="M40 58C26.7504 58 16 68.784 16 82C16 95.248 26.7504 106 40 106C53.2192 106 64 95.248 64 82C64 68.784 53.2192 58 40 58ZM40 122C17.9376 122 0 104.064 0 82C0 59.936 17.9376 42 40 42C62.0624 42 80 59.936 80 82C80 104.064 62.0624 122 40 122Z"
            fill="#219EBC"
          />
          <path
            opacity="0.2"
            d="M108.318 80.8427C107.891 80.608 105.786 79.4534 105.213 79.1414C104.641 78.8294 104.171 78.36 103.859 77.7867C103.546 77.2133 102.401 75.0987 102.161 74.6827C101.922 74.2667 101.484 74 101 74C100.516 74 100.078 74.2667 99.8381 74.6827C99.5989 75.0987 98.4536 77.2133 98.1411 77.7867C97.828 78.36 97.3595 78.8294 96.7869 79.1414C96.2139 79.4534 94.1088 80.608 93.6819 80.8427C93.2555 81.0773 93 81.52 93 82C93 82.48 93.2555 82.9227 93.6819 83.1573C94.1088 83.3893 96.2139 84.5466 96.7869 84.8586C97.3595 85.1706 97.828 85.64 98.1411 86.2133C98.4536 86.7867 99.5989 88.9013 99.8381 89.3173C100.078 89.7333 100.516 90 101 90C101.484 90 101.922 89.7333 102.161 89.3173C102.401 88.9013 103.546 86.7867 103.859 86.2133C104.171 85.64 104.641 85.1706 105.213 84.8586C105.786 84.5466 107.891 83.3893 108.318 83.1573C108.745 82.9227 109 82.48 109 82C109 81.52 108.745 81.0773 108.318 80.8427Z"
            fill="#009DFF"
          />
          <path
            opacity="0.2"
            d="M47 24C45.344 24 44 25.344 44 27C44 28.656 45.344 30 47 30C48.656 30 50 28.656 50 27C50 25.344 48.656 24 47 24Z"
            fill="#FF8C00"
          />
        </svg>
        <div class="block md:flex w-full items-center justify-center py-4">
          <p
            class="font-desain text-[#212121] font-semibold text-xl mr-3 mb-3 md:mb-0"
          >
            Tukarkan Vouchermu!
          </p>
          <form @submit.prevent="redeemVoucher" class="flex justify-between">
            <input
              type="text"
              required
              v-model="voucher"
              placeholder="Masukkan kode voucher disini"
              class="border border-gray-50 w-full md:w-80 p-2 rounded-md font-solusi text-[#212121] text-sm focus:outline-none focus:ring focus:border-blue-400 font-semibold"
            />
            <button
              type="submit"
              @click="redeemVoucher()"
              class="bg-[#00446F] rounded-full px-4 py-2 ml-2 outline-none focus:outline-none flex items-center"
              :disabled="loadingVoucher"
              :class="loadingVoucher ? 'cursor-wait' : 'cursor-pointer'"
            >
              <div
                v-if="!loadingVoucher"
                class="text-white font-solusi font-semibold text-sm"
              >
                Tukarkan
              </div>
              <div v-else class="px-3">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-5 h-5 animate-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
              </div>
            </button>
          </form>
        </div>
        <svg
          class="hidden md:block"
          width="143"
          height="124"
          viewBox="0 0 143 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M131 4.8C127.025 4.8 123.8 8.0352 123.8 12C123.8 15.9744 127.025 19.2 131 19.2C134.966 19.2 138.2 15.9744 138.2 12C138.2 8.0352 134.966 4.8 131 4.8ZM131 24C124.381 24 119 18.6192 119 12C119 5.3808 124.381 1.90735e-06 131 1.90735e-06C137.619 1.90735e-06 143 5.3808 143 12C143 18.6192 137.619 24 131 24Z"
            fill="#FFC200"
          />
          <path
            opacity="0.2"
            d="M142.318 60.8427C141.891 60.608 139.786 59.4534 139.213 59.1414C138.641 58.8294 138.171 58.36 137.859 57.7867C137.546 57.2133 136.401 55.0987 136.161 54.6827C135.922 54.2667 135.484 54 135 54C134.516 54 134.078 54.2667 133.838 54.6827C133.599 55.0987 132.454 57.2133 132.141 57.7867C131.828 58.36 131.359 58.8294 130.787 59.1414C130.214 59.4534 128.109 60.608 127.682 60.8427C127.255 61.0773 127 61.52 127 62C127 62.48 127.255 62.9227 127.682 63.1573C128.109 63.3893 130.214 64.5466 130.787 64.8586C131.359 65.1706 131.828 65.64 132.141 66.2133C132.454 66.7867 133.599 68.9013 133.838 69.3173C134.078 69.7333 134.516 70 135 70C135.484 70 135.922 69.7333 136.161 69.3173C136.401 68.9013 137.546 66.7867 137.859 66.2133C138.171 65.64 138.641 65.1706 139.213 64.8586C139.786 64.5466 141.891 63.3893 142.318 63.1573C142.745 62.9227 143 62.48 143 62C143 61.52 142.745 61.0773 142.318 60.8427Z"
            fill="#009DFF"
          />
          <path
            opacity="0.2"
            d="M80 -1.81198e-05C77.792 -1.81198e-05 76 1.79199 76 3.99999C76 6.208 77.792 8 80 8C82.208 8 84 6.208 84 3.99999C84 1.79199 82.208 -1.81198e-05 80 -1.81198e-05Z"
            fill="#FF8C00"
          />
        </svg>
      </div>
    </div>

    <!-- Section Kelas Saya -->
    <div
      v-if="Auth.isLoggedIn() && myClasses.length"
      class="my-5 max-w-6xl mx-auto bg-myclass myclass rounded-none md:rounded-md"
    >
      <div class="flex items-center justify-between px-3 pt-3">
        <p class="font-desain text-[#383838] font-semibold text-2xl">
          Kelas Saya
        </p>
        <router-link
          to="/my-classes"
          class="font-solusi text-tritary font-semibold text-base hover:text-[#274977]0 "
        >
          Lihat semua >
        </router-link>
      </div>
      <div class="px-3 pb-3">
        <carousel
          snapAlign="start"
          :settings="{
            itemsToShow: 1.5,
          }"
          :itemsToScroll="1"
          :breakpoints="{
            768: {
              itemsToShow: 4,
            },
          }"
        >
          <slide v-for="(myClass, i) in myClasses" :key="i">
            <div
              class="carousel__item p-2 m-3 rounded-md  hover:bg-[#F5F7FD] duration-300 w-full shadow-sm hover:shadow-md"
            >
              <div class="flex flex-col justify-between h-[14rem]">
                <img
                  @click.prevent="
                    $router.push('/classroom/' + myClass.code + '/activity')
                  "
                  :src="myClass.thumbnail"
                  alt=""
                  class="rounded-t-md object-cover w-64 h-32 cursor-pointer"
                />
                <div
                  class="flex flex-col flex-grow justify-between my-2 relative"
                >
                  <router-link
                    :to="'/classroom/' + myClass.code + '/activity'"
                    v-tooltip="{
                      content: myClass.name,
                    }"
                    class=" text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden"
                  >
                    {{ myClass.name }}
                  </router-link>
                  <!-- <div
                    v-if="myClass?.mentor_data"
                    class="flex items-center w-full"
                  >
                    <img
                      :src="myClass?.mentor_data[0]?.photo"
                      alt=""
                      class="rounded-full h-10 w-10"
                    />
                    <div class="flex flex-col w-full ml-2 truncate">
                      <p
                        class="font-solusi text-xs text-[#212121] font-semibold text-left mb-1"
                      >
                        {{ myClass?.mentor_data[0]?.name }}
                      </p>
                      <p class="font-solusi text-2xs text-[#383838] text-left">
                        {{ myClass?.mentor_data[0]?.attribution }}
                      </p>
                    </div>
                  </div> -->
                </div>
                <div class="flex flex-col">
                  <router-link
                    :to="'/classroom/' + myClass.code + '/activity'"
                    class="w-full rounded-full bg-[#00446F] text-white font-solusi text-xs text-center py-2 font-semibold mt-2"
                  >
                    Lanjutkan
                  </router-link>
                </div>
              </div>
            </div>
          </slide>
          <template #addons="{ slidesCount, currentSlide }">
            <navigation
              v-if="myClasses.length > 4"
              :class="{
                'is-first-slide': !currentSlide,
                'is-last-slide': currentSlide === slidesCount - 1,
              }"
            />
          </template>
        </carousel>
      </div>
    </div>
    <!-- Section myClass -->

    <!-- Section Webinar Schedule -->
    <div
      v-if="myWebinars.length > 0 && Auth.isLoggedIn()"
      class="my-5 max-w-6xl mx-auto rounded-none md:rounded-md"
    >
      <div class="flex items-center justify-between p-3">
        <p class="font-desain text-[#383838] font-semibold text-2xl">
          Jadwal Webinar
        </p>
        <!-- <a class="font-solusi text-tritary font-semibold text-base"
          >Lihat semua ></a
        > -->
      </div>
      <div class="px-3 pb-3">
        <carousel
          key="webinar-schedule"
          snapAlign="start"
          :settings="{
            itemsToShow: 1.5,
            itemsToScroll: 1.5,
          }"
          :breakpoints="{
            768: {
              itemsToShow: 4,
              itemsToScroll: 1,
            },
          }"
        >
          <slide v-for="(webinar, i) in myWebinars" :key="i">
            <div
              class="carousel__item p-2 mx-2 rounded-xl duration-300 relative shadow hover:shadow-md mb-3 w-full flex"
              :class="
                dateOnLive(webinar.expired_at) >= dateNow() &&
                dateOnLive(webinar.started_at) <= dateNow()
                  ? 'min-h-[18.5rem] bg-cover items-end bg-center'
                  : 'min-h-[18.5rem] flex-col bg-[#FFFFFF] hover:bg-[#EEF2FF]'
              "
              :style="
                dateOnLive(webinar.expired_at) >= dateNow() &&
                dateOnLive(webinar.started_at) <= dateNow()
                  ? `background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5)),url(${webinar.thumbnail})`
                  : ''
              "
            >
              <div
                v-if="
                  dateOnLive(webinar.expired_at) >= dateNow() &&
                    dateOnLive(webinar.started_at) <= dateNow()
                "
                class="absolute top-3 right-3 px-2 py-1 rounded-md bg-white flex items-center shadow-md"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  class="w-6 h-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5361 4.65584C14.4523 4.62522 14.353 4.63584 14.2823 4.68209L11.4817 6.52833C11.428 6.56333 11.3979 6.61521 11.3979 6.66896V9.33152C11.3979 9.38589 11.4286 9.43714 11.4817 9.47214L14.2823 11.3184C14.3261 11.3471 14.3805 11.3621 14.4361 11.3621C14.4698 11.3621 14.5042 11.3565 14.5361 11.3446C14.6198 11.3146 14.6742 11.249 14.6742 11.1771V4.82273C14.6742 4.75085 14.6205 4.68585 14.5361 4.65585L14.5361 4.65584Z"
                    fill="#DE1306"
                  />
                  <path
                    d="M9.81899 3.87866H2.28715C1.75715 3.87866 1.32715 4.26303 1.32715 4.73741V11.2624C1.32715 11.7367 1.75715 12.1211 2.28715 12.1211H9.81899C10.349 12.1211 10.779 11.7368 10.779 11.2624V4.73741C10.779 4.26305 10.349 3.87866 9.81899 3.87866Z"
                    fill="#DE1306"
                  />
                </svg>
                <span
                  class="ml-1 font-solusi text-base text-[#DE1306] font-semibold"
                  >Live</span
                >
              </div>

              <div class="flex flex-col justify-between flex-grow">
                <router-link
                  v-if="
                    !(
                      dateOnLive(webinar.expired_at) >= dateNow() &&
                      dateOnLive(webinar.started_at) <= dateNow()
                    )
                  "
                  :to="'/classroom/' + webinar?.classroom?.code + '/activity'"
                >
                  <img
                    :src="webinar.thumbnail"
                    alt=""
                    class="rounded-t-md object-cover w-64 h-32 w-full"
                  />
                </router-link>
                <div
                  class="flex flex-col flex-grow justify-between my-2 relative"
                >
                  <router-link
                    :to="'/classroom/' + webinar.classroom.code + '/activity'"
                    v-tooltip="{
                      content: webinar.name,
                    }"
                    class="text-sm font-solusi font-semibold text-left line-clamp-2 overflow-hidden mb-2"
                    :class="
                      dateOnLive(webinar.expired_at) >= dateNow() &&
                      dateOnLive(webinar.started_at) <= dateNow()
                        ? 'text-white'
                        : 'text-[#383838]'
                    "
                  >
                    {{ webinar.name }}
                  </router-link>
                  <div class="flex flex-col">
                    <div class="flex items-center my-1">
                      <svg
                        class="w-4 h-4"
                        :class="
                          dateOnLive(webinar.expired_at) >= dateNow() &&
                          dateOnLive(webinar.started_at) <= dateNow()
                            ? 'text-white'
                            : 'text-[#212121]'
                        "
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.6428 2.08349H16.095C16.095 1.74569 15.9608 1.42173 15.722 1.18287C15.4831 0.944005 15.1591 0.809815 14.8213 0.809815C14.4835 0.809815 14.1596 0.944005 13.9207 1.18287C13.6818 1.42173 13.5476 1.74569 13.5476 2.08349H8.45239C8.45239 1.74569 8.3182 1.42173 8.07934 1.18287C7.84048 0.944005 7.51652 0.809814 7.17871 0.809814C6.84091 0.809814 6.51695 0.944005 6.27809 1.18287C6.03923 1.42173 5.90503 1.74569 5.90503 2.08349H3.35718C2.68151 2.08357 2.03354 2.35205 1.55581 2.82987C1.07809 3.3077 0.809746 3.95573 0.809814 4.6314V18.6431C0.809812 18.9776 0.875703 19.3089 1.00373 19.6179C1.13175 19.9269 1.31939 20.2077 1.55595 20.4442C1.7925 20.6807 2.07332 20.8683 2.38238 20.9963C2.69144 21.1242 3.02268 21.1901 3.35718 21.19H18.6428C18.9773 21.1901 19.3086 21.1243 19.6176 20.9964C19.9267 20.8684 20.2076 20.6809 20.4441 20.4443C20.6807 20.2078 20.8684 19.927 20.9964 19.618C21.1244 19.3089 21.1902 18.9776 21.1902 18.6431V4.6314C21.1903 4.29683 21.1244 3.96551 20.9965 3.65638C20.8685 3.34725 20.6808 3.06636 20.4443 2.82975C20.2077 2.59315 19.9269 2.40546 19.6178 2.27742C19.3086 2.14938 18.9773 2.08348 18.6428 2.08349ZM18.6428 18.6431H3.35718V9.72712H18.6428V18.6431ZM18.6428 7.17821H3.35718V4.6314H5.905C5.905 4.96921 6.0392 5.29317 6.27806 5.53203C6.51692 5.77089 6.84088 5.90508 7.17868 5.90508C7.51649 5.90508 7.84045 5.77089 8.07931 5.53203C8.31817 5.29317 8.45236 4.96921 8.45236 4.6314H13.5476C13.5476 4.96921 13.6818 5.29317 13.9206 5.53203C14.1595 5.77089 14.4835 5.90508 14.8213 5.90508C15.1591 5.90508 15.483 5.77089 15.7219 5.53203C15.9608 5.29317 16.0949 4.96921 16.0949 4.6314H18.6427L18.6428 7.17821Z"
                          fill="currentColor"
                        />
                      </svg>
                      <p
                        class="ml-2 font-solusi text-xs"
                        :class="
                          dateOnLive(webinar.expired_at) >= dateNow() &&
                          dateOnLive(webinar.started_at) <= dateNow()
                            ? 'text-white font-semibold'
                            : 'text-[#212121]'
                        "
                      >
                        {{ getDateWebinar(webinar?.started_at) }}
                      </p>
                    </div>
                    <div class="flex items-center my-1">
                      <svg
                        class="w-4 h-4"
                        :class="
                          dateOnLive(webinar.expired_at) >= dateNow() &&
                          dateOnLive(webinar.started_at) <= dateNow()
                            ? 'text-white'
                            : 'text-[#212121]'
                        "
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.99984 1.16669C10.2216 1.16669 12.8332 3.77885 12.8332 7.00002C12.8332 10.2212 10.2216 12.8334 6.99984 12.8334C3.77809 12.8334 1.1665 10.2212 1.1665 7.00002C1.1665 3.77885 3.77809 1.16669 6.99984 1.16669ZM6.99984 2.1391C4.31942 2.1391 2.13892 4.3196 2.13892 7.00002C2.13892 9.68044 4.31942 11.8609 6.99984 11.8609C9.68025 11.8609 11.8608 9.68044 11.8608 7.00002C11.8608 4.3196 9.68025 2.1391 6.99984 2.1391ZM6.56234 3.50002C6.78371 3.50002 6.96686 3.66472 6.99584 3.87818L6.99984 3.93752V7.00002H8.89567C9.13717 7.00002 9.33317 7.19602 9.33317 7.43752C9.33317 7.6589 9.16848 7.84204 8.95501 7.87102L8.89567 7.87502H6.56234C6.34096 7.87502 6.15782 7.71033 6.12883 7.49686L6.12484 7.43752V3.93752C6.12484 3.69602 6.32084 3.50002 6.56234 3.50002Z"
                          fill="currentColor"
                        />
                      </svg>
                      <p
                        class="ml-2 font-solusi text-xs"
                        :class="
                          dateOnLive(webinar.expired_at) >= dateNow() &&
                          dateOnLive(webinar.started_at) <= dateNow()
                            ? 'text-white font-semibold'
                            : 'text-[#212121]'
                        "
                      >
                        {{
                          getTimeWebinar(
                            webinar?.started_at,
                            webinar?.expired_at
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <!-- <a
                    v-if="webinar?.link"
                    :href="webinar.link"
                    class="w-full rounded-full bg-[#009DFF] text-white font-solusi text-xs text-center py-2 font-semibold mt-2"
                  >
                    Join Webinar
                  </a> -->
                  <router-link
                    :to="
                      '/classroom/' +
                        webinar.classroom.code +
                        '/activity?tab=webinar'
                    "
                    class="w-full rounded-full bg-[#009DFF] text-white font-solusi text-xs text-center py-2 font-semibold mt-2"
                  >
                    Join Webinar
                  </router-link>
                </div>
              </div>
            </div>
          </slide>
          <template #addons="{ slidesCount, currentSlide }">
            <navigation
              v-if="myWebinars.length > 4"
              :class="{
                'is-first-slide': !currentSlide,
                'is-last-slide': currentSlide === slidesCount - 1,
              }"
            />
          </template>
        </carousel>
      </div>
    </div>
    <!-- Webinar Schedule -->

    <!-- Section Class Popular -->
    <div
      v-show="highlight?.classrooms?.length > 0"
      class="my-5 max-w-6xl mx-auto bg-gradient-to-b from-[#00446F] to-[#088BD5] rounded-none md:rounded-md"
    >
      <div class="flex items-center justify-between px-3 pt-3">
        <p class="font-desain text-white font-semibold text-2xl">
          {{ highlight.name }}
        </p>
        <router-link
          class="font-solusi text-white font-semibold text-base"
          :to="'/category?q=' + highlight.slug"
        >
          Lihat semua >
        </router-link>
      </div>
      <div
        id="popular"
        v-if="highlight?.classrooms?.length > 0"
        class="px-2 pb-5 slider-popular"
      >
        <carousel
          :autoplay="5000"
          :wrap-around="true"
          :settings="{
            itemsToShow: 1,
            snapAlign: 'start',
          }"
          :breakpoints="{
            768: {
              itemsToShow: 2.2,
              snapAlign: 'center',
            },
          }"
        >
          <slide v-for="(popular, i) in highlight?.classrooms" :key="i">
            <div
              @click="beforeAddToCart(popular)"
              class="carousel__item p-2 m-3 rounded-lg bg-white duration-300 w-full shadow hover:shadow-md cursor-pointer"
            >
              <div
                class="flex flex-row justify-between h-32 md:h-48 relative cursor-pointer"
              >
                <img
                  :src="popular.thumbnail"
                  alt=""
                  class="rounded-lg flex-auto object-cover w-32 md:w-48 object-center cursor-pointer"
                />
                <div
                  class="flex-auto flex flex-col flex-grow ml-3 justify-between box-border place-content-end"
                >
                  <div
                    class="flex flex-col flex-grow justify-between relative box-border"
                  >
                    <div
                      v-tooltip="{
                        content: popular.name,
                      }"
                      class=" text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden cursor-pointer"
                    >
                      {{ popular.name }}
                    </div>
                    <div
                      v-if="popular?.mentor_data"
                      class="flex items-center w-full"
                    >
                      <img
                        :src="popular?.mentor_data[0]?.photo"
                        alt=""
                        class="rounded-full h-10 w-10"
                      />
                      <div class="flex flex-col w-full ml-2 truncate">
                        <p
                          class="font-solusi text-xs text-[#212121] font-semibold text-left mb-1 truncate"
                        >
                          {{ popular?.mentor_data[0]?.name }}
                        </p>
                        <p
                          class="font-solusi text-2xs text-[#383838] text-left truncate"
                        >
                          {{ popular?.mentor_data[0]?.attribution }}
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center">
                      <div v-for="i in 5" :key="i" class="">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          class="w-4 h-4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.0489 2.92705C17.3483 2.00574 18.6517 2.00574 18.9511 2.92705L21.8167 11.7467C21.9506 12.1587 22.3346 12.4377 22.7678 12.4377H32.0413C33.0101 12.4377 33.4128 13.6773 32.6291 14.2467L25.1267 19.6976C24.7762 19.9522 24.6295 20.4036 24.7634 20.8156L27.6291 29.6353C27.9284 30.5566 26.874 31.3227 26.0902 30.7533L18.5878 25.3024C18.2373 25.0478 17.7627 25.0478 17.4122 25.3024L9.90976 30.7533C9.12605 31.3227 8.07157 30.5566 8.37092 29.6353L11.2366 20.8156C11.3705 20.4036 11.2238 19.9522 10.8733 19.6976L3.37088 14.2467C2.58717 13.6773 2.98994 12.4377 3.95867 12.4377H13.2322C13.6654 12.4377 14.0494 12.1587 14.1833 11.7467L17.0489 2.92705Z"
                            fill="#FFC200"
                          />
                        </svg>
                      </div>
                      <div class="ml-1 flex flex-grow items-center">
                        <span
                          class="font-solusi text-xs font-semibold text-[#212121] mx-1"
                          >{{ popular?.rating }}</span
                        >
                        <span
                          class="font-solusi text-xs font-normal text-[#9E9E9E]"
                          >({{ popular?.subscribers }} Rating)</span
                        >
                      </div>
                    </div>
                    <div class="hidden md:flex items-center">
                      <div class="font-desain text-xl font-semibold mr-1">
                        Rp. {{ popular?.rupiah_price_formatted }}
                      </div>
                      <div
                        v-if="popular?.normal_price > 0"
                        class="font-solusi text-[#DE1306] text-sm font-normal line-through"
                      >
                        Rp. {{ popular?.normal_price_formatted }}
                      </div>
                    </div>
                  </div>
                  <router-link
                    v-if="popular?.is_purchased"
                    :to="'/classroom/' + popular?.code + '/activity'"
                    class="hidden md:block rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 w-44 bg-[#00446F]"
                  >
                    Lanjutkan
                  </router-link>
                  <button
                    v-else
                    class="hidden md:block rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 w-44"
                    :class="
                      popular?.must_voucher
                        ? 'bg-[#FB8500]'
                        : checkWorkshopClass(popular?.code)
                        ? 'bg-[#219EBC]'
                        : isPreOrder(popular?.started_at)
                        ? 'bg-[#FB8500]'
                        : 'bg-[#219EBC]'
                    "
                  >
                    {{
                      popular?.must_voucher
                        ? 'Tukarkan Voucher'
                        : checkWorkshopClass(popular?.code)
                        ? 'Beli Kelas'
                        : isPreOrder(popular?.started_at)
                        ? 'Pre-Order Sekarang'
                        : 'Beli Kelas'
                    }}
                  </button>
                </div>
              </div>
              <div
                class="block md:hidden border-b-2 border-[#F5F6F8] w-full my-2"
              ></div>
              <div class="flex md:hidden items-start justify-between">
                <div class="flex items-center">
                  <p class="font-desain text-base font-semibold mr-1">
                    Rp. {{ popular?.rupiah_price_formatted }}
                    <span
                      v-if="popular?.normal_price > 0"
                      class="font-solusi text-[#DE1306] text-xs font-normal line-through"
                    >
                      Rp. {{ popular?.normal_price_formatted }}
                    </span>
                  </p>
                </div>
                <button
                  class="rounded-full text-white font-solusi text-xs text-center py-2 font-semibold w-40"
                  :class="
                    popular?.must_voucher
                      ? 'bg-[#FB8500]'
                      : checkWorkshopClass(popular?.code)
                      ? 'bg-[#219EBC]'
                      : isPreOrder(popular?.started_at)
                      ? 'bg-[#FB8500]'
                      : 'bg-[#219EBC]'
                  "
                >
                  {{
                    popular?.must_voucher
                      ? 'Tukarkan Voucher'
                      : checkWorkshopClass(popular?.code)
                      ? 'Beli Kelas'
                      : isPreOrder(popular?.started_at)
                      ? 'Pre-Order Sekarang'
                      : 'Beli Kelas'
                  }}
                </button>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation v-if="highlight?.classrooms?.length > 1" />
            <pagination />
          </template>
        </carousel>
      </div>
    </div>
    <!-- Section Popular -->

    <!-- Section News -->
    <div
      v-if="news.length"
      class="bg-gradient-to-b from-[#F0FBFF] to-[#F7E4CC] mt-5"
    >
      <div class="max-w-6xl mx-auto rounded-none md:rounded-md">
        <div class="flex items-center justify-between p-3">
          <p class="font-desain text-[#383838] font-semibold text-2xl">
            Berita
          </p>
          <a
            href="https://blog.sekolahdesain.id"
            target="_blank"
            class="font-solusi text-tritary hover:text-[#274977]0 font-semibold text-base"
            >Lihat semua ></a
          >
        </div>
        <div class="px-3 pb-3">
          <carousel
            key="news"
            snapAlign="start"
            :settings="{
              itemsToShow: 1.15,
            }"
            :itemsToScroll="1"
            :breakpoints="{
              768: {
                itemsToShow: 3,
              },
            }"
          >
            <slide v-for="(news, i) in news" :key="i">
              <div
                class="carousel__item m-2 rounded-md hover:bg-[#EEF2FF] duration-300 w-full shadow hover:shadow-md bg-white"
              >
                <div class="flex flex-row justify-between h-20 relative">
                  <img
                    v-if="news.thumbnail"
                    :src="news.thumbnail"
                    alt=""
                    class="rounded-lg flex-auto w-20 h-20 cursor-pointer flex-grow-0"
                  />
                  <div
                    class="flex flex-col flex-grow justify-between relative box-border place-content-end"
                  >
                    <div
                      class="flex flex-col flex-grow justify-between relative box-border p-2"
                    >
                      <a
                        :href="news?.link"
                        v-tooltip="{
                          content: news.title,
                        }"
                        class=" text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden"
                      >
                        {{ news.title }}
                      </a>
                      <p class="font-solusi text-2xs text-[#757575] text-left">
                        {{ newsFormatted(news.date) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons="{ slidesCount, currentSlide }">
              <navigation
                :class="{
                  'is-first-slide': !currentSlide,
                  'is-last-slide': currentSlide === slidesCount - 1,
                }"
              />
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <!--  Section News -->

    <!-- Section Kelas Kategori -->
    <div
      :id="category.slug"
      v-show="category.classrooms.length > 0"
      v-for="(category, index) in categories"
      :key="index"
      class="my-5 max-w-6xl mx-auto rounded-none md:rounded-md"
    >
      <div class="flex items-center justify-between p-3">
        <p class="font-desain text-[#383838] font-semibold text-2xl">
          {{ category.name }}
        </p>
        <router-link
          class="font-solusi text-tritary font-semibold text-base hover:text-[#274977]0"
          :to="'/category?q=' + category.slug"
        >
          Lihat semua >
        </router-link>
      </div>
      <div class="px-3 pb-3">
        <carousel
          :key="category.slug + index"
          v-if="category.classrooms.length > 0"
          snapAlign="start"
          :settings="{
            itemsToShow: 1.5,
          }"
          :itemsToScroll="4"
          :breakpoints="{
            768: {
              itemsToShow: 4,
            },
          }"
        >
          <slide v-for="(classroom, i) in category.classrooms" :key="i">
            <div
              @click="beforeAddToCart(classroom)"
              class="carousel__item p-2 m-2 rounded-md bg-[#FFFFFF] hover:bg-[#EEF2FF] duration-300 w-full shadow hover:shadow-md cursor-pointer"
            >
              <div class="flex flex-col justify-between min-h-[21rem]">
                <img
                  :src="classroom.thumbnail"
                  alt=""
                  class="rounded-t-md w-full h-32 cursor-pointer"
                />
                <div
                  class="flex flex-col flex-grow justify-between my-2 relative"
                >
                  <div
                    v-tooltip="{
                      content: classroom.name,
                    }"
                    class="text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden mb-2 cursor-pointer"
                  >
                    {{ classroom.name }}
                  </div>
                  <div
                    v-if="classroom?.mentor_data"
                    class="flex items-center w-full"
                  >
                    <img
                      :src="classroom?.mentor_data[0]?.photo"
                      alt=""
                      class="rounded-full h-10 w-10"
                    />
                    <div class="flex flex-col w-full ml-2 truncate">
                      <p
                        class="font-solusi text-xs text-[#212121] font-semibold text-left mb-1"
                      >
                        {{ classroom?.mentor_data[0]?.name }}
                      </p>
                      <p class="font-solusi text-2xs text-[#383838] text-left">
                        {{ classroom?.mentor_data[0]?.attribution }}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center my-2">
                    <div v-for="i in 5" :key="i" class="">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        class="w-4 h-4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0489 2.92705C17.3483 2.00574 18.6517 2.00574 18.9511 2.92705L21.8167 11.7467C21.9506 12.1587 22.3346 12.4377 22.7678 12.4377H32.0413C33.0101 12.4377 33.4128 13.6773 32.6291 14.2467L25.1267 19.6976C24.7762 19.9522 24.6295 20.4036 24.7634 20.8156L27.6291 29.6353C27.9284 30.5566 26.874 31.3227 26.0902 30.7533L18.5878 25.3024C18.2373 25.0478 17.7627 25.0478 17.4122 25.3024L9.90976 30.7533C9.12605 31.3227 8.07157 30.5566 8.37092 29.6353L11.2366 20.8156C11.3705 20.4036 11.2238 19.9522 10.8733 19.6976L3.37088 14.2467C2.58717 13.6773 2.98994 12.4377 3.95867 12.4377H13.2322C13.6654 12.4377 14.0494 12.1587 14.1833 11.7467L17.0489 2.92705Z"
                          fill="#FFC200"
                        />
                      </svg>
                    </div>
                    <div class="ml-1 flex flex-grow items-center">
                      <span
                        class="font-solusi text-xs font-semibold text-[#212121] mx-1"
                        >{{ classroom?.rating }}</span
                      >
                      <span
                        class="font-solusi text-xs font-normal text-[#9E9E9E]"
                        >({{ classroom?.subscribers }} Rating)</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="font-desain text-xl font-semibold mr-1">
                      Rp. {{ classroom?.rupiah_price_formatted }}
                    </div>
                    <div
                      v-if="classroom?.normal_price > 0"
                      class="font-solusi text-[#DE1306] text-sm font-normal line-through"
                    >
                      Rp. {{ classroom?.normal_price_formatted }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <router-link
                    v-if="classroom.is_purchased"
                    :to="'/classroom/' + classroom?.code + '/activity'"
                    class="w-full rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 bg-[#00446F]"
                  >
                    Lanjutkan
                  </router-link>
                  <button
                    v-else
                    class="w-full rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2"
                    :class="
                      classroom?.must_voucher
                        ? 'bg-[#FB8500]'
                        : checkWorkshopClass(classroom?.code)
                        ? 'bg-[#219EBC]'
                        : isPreOrder(classroom?.started_at)
                        ? 'bg-[#FB8500]'
                        : 'bg-[#219EBC]'
                    "
                  >
                    {{
                      classroom?.must_voucher
                        ? 'Tukarkan Voucher'
                        : checkWorkshopClass(classroom?.code)
                        ? 'Beli Kelas'
                        : isPreOrder(classroom?.started_at)
                        ? 'Pre-Order Sekarang'
                        : 'Beli Kelas'
                    }}
                  </button>
                </div>
              </div>
            </div>
          </slide>
          <template #addons="{ slidesCount, currentSlide }">
            <navigation
              v-if="category.classrooms.length > 4"
              :class="{
                'is-first-slide': !currentSlide,
                'is-last-slide': currentSlide === slidesCount - 1,
              }"
            />
          </template>
        </carousel>
      </div>
    </div>
    <!--  Section Kategori -->

    <!-- Popup Category -->
    <transition name="fade">
      <div
        v-if="showModalCategory"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showModalCategory"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/2 my-auto md:my-6 mx-2 md:mx-auto">
          <div
            v-click-away="closeModalCategory"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none divide-y divide-gray-200 max-h-[80vh]"
          >
            <div class="flex items-center justify-between px-5 py-3 rounded-t">
              <p class="font-desain text-xl text-[#212121] font-semibold">
                Semua Kategori
              </p>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalCategory()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="overflow-y-auto divide-y divide-gray-200 scroll-custom">
              <div class="p-5 mb-3">
                <div class="grid grid-cols-4 md:grid-cols-5 gap-2">
                  <router-link
                    v-for="(category, i) in categories"
                    :key="i"
                    :to="'/category?q=' + category.slug"
                    class="col-span-1 flex flex-col items-center justify-start text-[#212121] hover:text-[#0A72ED] mb-2"
                  >
                    <img
                      :src="category?.thumbnail"
                      alt=""
                      class="rounded-full w-16 h-16"
                    />
                    <div
                      class="font-solusi font-semibold text-xs mt-1 text-center"
                    >
                      {{ category.name }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Popup Voucher -->
    <transition name="fade">
      <div
        v-if="showModalVoucher"
        class="opacity-50 fixed inset-0 z-51 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showModalVoucher"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <div
            v-click-away="closeModalVoucher"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <div class="flex items-start justify-end px-3 pt-3 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalVoucher()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto">
              <img
                :src="
                  redeemStatus
                    ? '/assets/images/modal/voucher-success.svg'
                    : '/assets/images/modal/voucher-failed.svg'
                "
                alt=""
                class="w-36 md:w-40 m-auto"
              />
              <p
                class="font-desain text-[#212121] text-lg text-center my-2 font-semibold"
              >
                {{ redeemStatus ? 'Berhasil' : 'Gagal' }} Menukarkan Voucher
              </p>
              <p
                v-if="redeemStatus"
                class="font-solusi text-[#212121] text-sm text-center mb-4"
              >
                {{ voucherDetail }}
              </p>
              <p
                v-else
                class="font-solusi text-[#212121] text-sm text-center mb-4"
              >
                Sayang sekali, kode voucher yang kamu masukkan tidak valid,
                tukarkan voucher lainnya!
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Popup Add To Cart -->
    <transition name="fade">
      <div
        v-if="showModalAddToCart"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalAddToCart"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-auto md:my-6 mx-2 md:mx-auto">
          <div
            v-click-away="closeModalAddToCart"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none divide-y divide-gray-200 max-h-[65vh]"
          >
            <div class="flex items-center justify-between px-5 py-3 rounded-t">
              <div class="flex items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="mr-2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#2FA742" />
                  <path
                    d="M9.27366 17.9117C9.54836 18.1935 9.93059 18.3532 10.3301 18.3532C10.3339 18.3532 10.338 18.3532 10.3423 18.3532C10.7464 18.3498 11.1307 18.1838 11.4033 17.8943L19.3842 9.41787C19.9253 8.84321 19.8833 7.95201 19.2909 7.42732C18.6983 6.903 17.7792 6.94339 17.2381 7.51786L10.312 14.8737L8.15605 12.6598C7.60471 12.0939 6.68489 12.0687 6.10178 12.6028C5.51827 13.1371 5.49231 14.0287 6.04308 14.5945L9.27366 17.9117Z"
                    fill="white"
                  />
                </svg>
                <p class="font-desain text-lg text-[#212121] font-semibold">
                  Berhasil ditambahkan ke keranjang
                </p>
              </div>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalAddToCart()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="overflow-y-auto divide-y divide-gray-200 scroll-custom">
              <div
                v-for="(cart, i) in getCartData"
                :key="i"
                class="px-6 py-4 hover:bg-gray-100 duration-300"
              >
                <div class="grid grid-cols-4 gap-2">
                  <div class="col-span-3 flex items-center">
                    <img
                      :src="cart.thumbnail"
                      alt=""
                      class="object-cover rounded-md h-20 w-20"
                    />
                    <div
                      class="ml-2 flex-grow flex flex-col justify-start h-20 relative"
                    >
                      <router-link
                        :to="'/classroom/' + cart.code"
                        class="font-solusi text-sm text-[#383838] line-clamp-3 font-semibold hover:text-[#0A72ED] mb-1 overflow-hidden"
                      >
                        {{ cart.name }}
                      </router-link>
                      <p
                        v-if="cart.mentor_data"
                        class="font-solusi text-xs text-[#757575] font-medium"
                      >
                        Oleh {{ cart.mentor_data[0].name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-span-1">
                    <p
                      class="font-solusi text-sm text-[#383838] text-right font-semibold"
                    >
                      Rp. {{ cart.price_formatted }}
                    </p>
                    <p
                      class="font-solusi text-xs text-[#DE1306] text-right font-medium line-through"
                    >
                      Rp. {{ cart.normal_price_formatted }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex justify-between items-center px-5 py-3">
                <p class="font-desain text-lg text-[#383838] font-semibold">
                  Total Harga
                </p>
                <p class="font-desain text-lg textblack-60 font-semibold">
                  Rp. {{ cartTotalPriceFormat() }}
                </p>
              </div>
              <div class="grid grid-cols-2 gap-6 px-5 mb-6">
                <router-link
                  to="/cart"
                  class="text-center bg-white rounded-full border-2 border-[#00446F] text-[#00446F] py-2 font-solusi font-semibold"
                >
                  Lihat Keranjang
                </router-link>
                <router-link
                  to="/cart?next=buy-now"
                  class="text-center bg-[#00446F] rounded-full border-2 border-[#00446F] text-white py-2 font-solusi font-semibold"
                >
                  Lanjut Bayar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Loading :show="showLoading" />
    <RedeemVoucher
      :show="showRedeemVoucher"
      v-model="codeRedeemVoucher"
      :loading="loadingRedeemVoucher"
      :close="closeRedeemVoucher"
      :redeem="submitRedeemVoucher"
      :classroom="selectedClassroom"
      @update:code="onChangeCode"
    />
  </div>
</template>

<script lang="ts">
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { Slider, Classroom, MyWebinar, Cart } from '@/dto'
import SliderModel from '@/models/SliderModel'
import ClassroomModel from '@/models/ClassroomModel'
import CartModel from '@/models/CartModel'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import Auth from '@/utils/Auth'
import moment from 'moment'
import Loading from '@/main/components/Loading.vue'
import RedeemVoucher from '@/main/components/Cards/RedeemVoucher.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home Page',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    RedeemVoucher,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      sliders: [] as Slider[],
      allClassrooms: [] as Classroom[],
      voucher: '',
      carts: [] as Cart[],
      showModalVoucher: false,
      redeemStatus: false,
      loadingVoucher: false,
      voucherDetail: '' as any,
      highlight: [] as any,
      loadingHighlight: false,
      myClasses: [] as any,
      loadedClassroomAmount: 0 as number,
      loadingMyClass: false,
      loadingCategory: false,
      categories: [] as any,
      isLoadingMyClassroom: false,
      myWebinars: [] as MyWebinar[],
      openWebinar: '',
      datesWebinar: [] as string[],
      showWebinar: [] as any,
      showModalAddToCart: false,
      showModalCategory: false,
      news: [] as any,
      showLoading: false as boolean,

      showRedeemVoucher: false as boolean,
      codeRedeemVoucher: '' as string,
      loadingRedeemVoucher: false as boolean,
      selectedClassroom: {} as Classroom,
    }
  },
  async created() {
    if (!Auth.isLoggedIn()) {
      window.location.href = 'https://www.sekolahdesain.id'
    }
    await this.getHighlight()
    if (window.innerWidth < 768) {
      const popular: HTMLElement = document.getElementById(
        'popular'
      ) as HTMLElement
      popular?.children[0]?.children[0]?.children[0]?.setAttribute(
        'style',
        'transform: translateX(0);'
      )
    } else {
      if (this.highlight?.classrooms?.length > 1) {
        const popular: HTMLElement = document.getElementById(
          'popular'
        ) as HTMLElement
        popular?.children[0]?.children[0]?.children[0]?.setAttribute(
          'style',
          'transform: translateX(-13rem);'
        )
      } else {
        const popular: HTMLElement = document.getElementById(
          'popular'
        ) as HTMLElement
        popular?.children[0]?.children[0]?.children[0]?.setAttribute(
          'style',
          'transform: translateX(19rem);'
        )
      }
    }
  },
  async mounted() {
    this.getSliders()
    this.$store.dispatch('homeStore/fetchCategories').then(() => {
      // for pagination
      this.categories = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      this.loadingCategory = this.getLoadingCategory
    })
    this.getNews()
    if (Auth.isLoggedIn()) {
      this.$store.dispatch('homeStore/fetchMyWebinar').then(() => {
        if (this.$store.getters['homeStore/getMyWebinar']?.length > 0) {
          this.myWebinars = this.$store.getters[
            'homeStore/getMyWebinar'
          ].filter((el: any) => {
            if (this.dateOnLive(el?.expired_at) >= this.dateNow()) {
              return el
            }
          })
        }
      })
      this.myClassrooms()
    }
  },

  computed: {
    getLoadingCategory(): boolean {
      return this.$store.getters['homeStore/getLoadingCategory']
    },
    getCategoriesData(): Array<any> {
      const category = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      return category
    },
    getLoadingMyWebinar(): boolean {
      return this.$store.getters['homeStore/getLoadingMyWebinar']
    },
    getMyWebinar(): Array<any> {
      return this.$store.getters['homeStore/getMyWebinar']
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
  },
  methods: {
    async getSliders() {
      const { data } = await SliderModel.list()
      this.sliders = data
        .sort(
          (a: { ordering: number }, b: { ordering: number }) =>
            a.ordering - b.ordering
        )
        .filter((e: Slider) => e.is_active)
    },
    closeModalCategory() {
      this.showModalCategory = false
    },
    closeModalVoucher() {
      this.showModalVoucher = false
      this.voucher = ''
      this.voucherDetail = null
    },
    async redeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.voucher) {
          this.loadingVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.voucher
            )
            if (success) {
              this.myClassrooms()
              this.voucherDetail = message
              this.loadingVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },

    async getHighlight() {
      if (Auth.isLoggedIn()) {
        await ClassroomModel.getHighlightPrivate()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
              this.loadingHighlight = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        await ClassroomModel.getHighlight()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
              this.loadingHighlight = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    async myClassrooms() {
      this.loadedClassroomAmount = 9999
      this.$store
        .dispatch('homeStore/fetchMyClass', {
          page: 1,
          showPerPage: 9999,
        })
        .then(() => {
          const {
            'homeStore/getLoadingSelfClassroom': getLoadingSelfClassroom,
            'homeStore/getSelfClassrooms': getSelfDataClassrooms,
          } = this.$store.getters
          this.myClasses = getSelfDataClassrooms
          this.loadingMyClass = getLoadingSelfClassroom
        })
    },

    async getNews() {
      await ClassroomModel.newsList(1, 25)
        .then(res => {
          if (res.success) {
            this.news = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    isPreOrder(date: string) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > this.timeZone(date)) {
        return false
      } else {
        return true
      }
    },

    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },

    redirectClassroom(code: string, slug: string) {
      if (slug != '') {
        window.location.href = slug
      } else {
        this.$router.push('/classroom/' + code + '/')
      }
    },

    async addToCart(code: string) {
      if (Auth.isLoggedIn()) {
        this.showLoading = true
        await CartModel.addSingle(code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.showModalAddToCart = true
            }
            this.showLoading = false
          })
          .catch(err => {
            this.showLoading = false
            console.log(err)
          })
      } else {
        this.$router.push('/register?redirect=/classroom/' + code)
      }
    },

    closeModalAddToCart() {
      this.showModalAddToCart = false
    },

    cartTotalPriceFormat() {
      let sum = 0
      this.getCartData.forEach((el: any) => {
        sum += el.price
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    getDateWebinar(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY')
    },

    getTimeWebinar(start: string, end: string) {
      const hourStart = moment(start).format('HH:mm')
      const hourEnd = moment(end).format('HH:mm')
      return hourStart + ' - ' + hourEnd + ' WIB'
    },

    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },

    newsFormatted(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY')
    },

    dateOnLive(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    beforeAddToCart(classroom: any) {
      if (classroom.is_purchased) {
        this.$router.push('/classroom/' + classroom?.code + '/activity')
      } else {
        if (classroom?.must_voucher) {
          this.selectedClassroom = classroom
          this.openRedeemVoucher()
        } else {
          this.addToCart(classroom.code)
        }
      }
    },
    checkWorkshopClass(code: string) {
      if (code.charAt(0) == 'W' || code.charAt(0) == 'M') {
        return true
      } else {
        return false
      }
    },

    closeRedeemVoucher() {
      this.showRedeemVoucher = false
    },

    openRedeemVoucher() {
      this.showRedeemVoucher = true
      this.codeRedeemVoucher = ''
      this.loadingRedeemVoucher = false
    },

    async submitRedeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.codeRedeemVoucher) {
          this.loadingRedeemVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.codeRedeemVoucher
            )
            if (success) {
              this.closeRedeemVoucher()
              this.myClassrooms()
              this.voucherDetail = message
              this.loadingRedeemVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingRedeemVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingRedeemVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },
  },
})
</script>

<style>
.slider .vueperslides__parallax-wrapper {
  padding-bottom: 39% !important;
}

@media (min-width: 768px) {
  .slider-popular .carousel__slide > .carousel__item {
    transform: scale(1);
    opacity: 0.5;
    transition: 0.5s;
  }
  .slider-popular .carousel__slide--visible > .carousel__item {
    opacity: 1;
    transform: rotateY(0);
  }
  .slider-popular .carousel__slide--next > .carousel__item {
    transform: scale(0.9) translate(-10px);
  }
  .slider-popular .carousel__slide--prev > .carousel__item {
    transform: scale(0.9) translate(10px);
  }
  .slider-popular .carousel__slide--active > .carousel__item {
    transform: scale(1.1);
  }
}

.my-class .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin: 0 !important;
}
.carousel__prev.is-first-slide {
  display: none;
}
.carousel__next.is-last-slide {
  display: none;
}
.carousel__next,
.carousel__prev {
  background-color: #ffffff !important;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
.carousel__next .carousel__icon,
.carousel__prev .carousel__icon {
  color: #009dff;
  width: 1.5rem;
  height: 1.5rem;
}
@media (max-width: 768px) {
  .carousel__next,
  .carousel__prev {
    display: none;
  }
  .slider .vueperslides__parallax-wrapper {
    padding-bottom: 41% !important;
  }
}
</style>
